import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private urlConfig = "./assets/config.json";
  constructor(private http: HttpClient) {
   }
  getConfigs(): Promise<Config> {
    return this.http.get(this.urlConfig)
      .toPromise()
      .then((res) => {
        Config.MapUrl = res['MapUrl'];
        Config.NadmUrl = res['NadmUrl'];
        Config.WebAPIServiceUrl = res['WebAPIServiceUrl'];
        Config.StratAgesUrl = res['StratAgesUrl'];
        Config.StratAgesColorUrl = res['StratAgesColorUrl'];
        Config.StrelRayonHierarchyUrl = res['StrelRayonHierarchyUrl'];
        Config.BabinRayonHierarchyUrl = res['BabinRayonHierarchyUrl'];
        Config.CompositionColor = res['CompositionColor'];
        Config.GenesislexiconUrl = res['GenesislexiconUrl'];
        Config.StratagelexiconUrl = res['StratagelexiconUrl'];
        Config.StrelRayonlexiconUrl = res['StrelRayonlexiconUrl'];
        Config.ShokRayonlexiconUrl = res['ShokRayonlexiconUrl'];
        Config.CubesUrl = res['CubesUrl'];
        Config.GeochronServiceUrl = res['GeochronServiceUrl'];
        Config.GeochronAtlasServiceUrl = res['GeochronAtlasServiceUrl'];
        return 'ok';
      });
  }  
}
export class Config {
  static MapUrl: string;
  static NadmUrl: string;
  static WebAPIServiceUrl: string;
  static StratAgesUrl: string;
  static StratAgesColorUrl: string;
  static StrelRayonHierarchyUrl: string;
  static BabinRayonHierarchyUrl: string;
  static CompositionColor: string;
  static GenesislexiconUrl: string;
  static StratagelexiconUrl: string;
  static StrelRayonlexiconUrl: string;
  static ShokRayonlexiconUrl: string;
  static CubesUrl: string;
  static GeochronServiceUrl: string;
  static GeochronAtlasServiceUrl: string;
}