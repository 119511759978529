
export class QueryParams {
    ru: boolean = true;
    // parent_code: string;
    // rayon_name: string;

    strel_rayons: string[];
    shok_rayons: string[];
    babin_rayons: string[];

    group_by_sub_unit: boolean;
    genesis: string;
    num_min_age: string;
    num_max_age: string;
    abs_age: boolean;
    all_formations: boolean;
    other_formations: boolean;
    formations: string[];
    minor_lithology: string;
    major_lithology: string[];
    abs_age_type: [boolean, boolean, boolean, boolean];
}
