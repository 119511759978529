import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StratService, sNode } from '../_services/strat.service';
import { FormControl, Validators } from '@angular/forms';

export interface DialogData {
  min: number;
  max: number;
}


@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss', '../object-selection/object-selection.component.scss']
})
export class ImageDialogComponent {

  valueFormControl = new FormControl('', [
    Validators.required, Validators.pattern('[0-9a-zA-Zа-яА-Я_]*')
  ]);
  getErrorMessage() {
    return this.valueFormControl.hasError('required') ? 'Строка пуста' :
        this.valueFormControl.hasError('pattern') ? 'Строка невалидна' :
            '';
  }
  agesNodes: sNode[];
  ages: [number, number] = [0, 13];
  configSlider: any = {
    behaviour: 'drag',
    orientation: 'vertical',
    connect: true,
    step: 1,
    margin: 1,
    start: 0,
    range: { min: 0, max: 13 }
  };

  constructor(public dialogRef: MatDialogRef<ImageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    this.agesNodes = StratService.nodes.filter(node => node.level == 1);
    this.agesNodes.forEach(node => {
      if (node.includes(data.min)) this.ages[0] = this.agesNodes.indexOf(node);
      if (node.includes(data.max)) this.ages[1] = this.agesNodes.indexOf(node) + 1;
    });

    console.log(this.agesNodes);
  }


}
