import { Epoch, RepoService } from '../_services/repo.service';
import { Cube } from './cube';
import { Column } from './column';

// import { Cube, Column, Globals, Epoch } from './ggkclasses';

export class RayonCharts {
    title: string;
    rayoncode: number;
    charts: Chart[];
    maxvalue: number;
    colscount: number;
    indtypes: Array<[string, string, string, string]>;

    SetValues() {
        let maxvalue = 0;
        let colscount = 0;
        let indtypes = new Array();
        for (let chart of this.charts) {
            if (maxvalue < chart.maxvalue) maxvalue = chart.maxvalue;
            colscount = chart.columns.length;
            chart.columns.forEach(col => {
                let ind = [this.getConsist(col.cubes[0].map_index), col.cubes[0].color, col.cubes[0].form_key, col.cubes[0].rus_txt];
                if (indtypes.filter(item => item[0] == ind[0]).length == 0)
                    indtypes.push(ind);
            });
        }
        this.maxvalue = maxvalue;
        this.colscount = colscount;
        this.indtypes = indtypes;
    }
    private getConsist(ind: string) {// slice mapindex to get consistance code
        for (let i = 0; i < ind.length; i++)
            if (ind[i] == ind[i].toUpperCase())
                if (i == 0) return '-';
                else return ind.slice(0, i);
        return ind;
    }
}

export class Chart {
    rayoncode: number;
    epoch: Epoch;
    title: string;
    maxvalue: number;
    columns: ChartColumn[];
    constructor(props: { title: string, rayoncode: number, epoch: Epoch, columns: ChartColumn[] }) {
        Object.assign(this, props);
        this.maxvalue = 0;
        for (let col of this.columns) {
            if (this.maxvalue < col.value) this.maxvalue = col.value;
        }
    }
}
export class ChartColumn {
    // title: string;
    indtype: string;
    tooltip: string;
    value: number;
    cubes: Cube[];
    constructor(props: { cubes: Cube[] }) {
        Object.assign(this, props);
        this.value = 0;
        for (let cube of this.cubes) {
            this.value += cube.sumArea;
        }
        this.value = Math.round(this.value * 100) / 100;
        // this.title = this.cubes[0].rayon;
        this.tooltip = this.cubes[0].rus_txt;
        this.indtype = this.cubes[0].form_key;
    }
}

export class ChartHelper {
    constructor() {
    }

    static createChart(columns: Column[]): RayonCharts[] {
        let charts: RayonCharts[] = new Array();
        if (columns.length == 0) return charts;
        let cols = columns.slice(0);
        // if (columns.length > 1) { // general chart for more than one rayon
        let allcubes: Cube[] = new Array();
        for (let col of columns) {
            allcubes = allcubes.concat(col.cubes);
        }
        let gencol = new Column({ cubes: allcubes, title: "Все выбранные районы" });
        cols.reverse();
        cols.push(gencol);
        cols.reverse();
        // }
        for (let col of cols) {
            let rayoncode = col.cubes[0].rayonCode;
            let rayonCharts: RayonCharts = new RayonCharts();
            rayonCharts.title = col.title;
            rayonCharts.rayoncode = rayoncode;
            rayonCharts.charts = new Array();
            for (let epoch of RepoService.TectEpoch) {
                let minage = epoch.minage;
                let maxage = epoch.maxage;
                let chart = new Chart({ title: col.title, rayoncode: rayoncode, epoch: epoch, columns: ChartHelper.getchartforepoch(minage, maxage, col) });
                rayonCharts.charts.push(chart);
            }
            charts.push(rayonCharts);
        }
        charts[0].title = "Все выбранные районы";
        return charts;
    }
    static getchartforepoch(minage: number, maxage: number, col: Column): ChartColumn[] {
        let epochchart: ChartColumn[] = new Array();
        let cubes = col.cubes.filter(c =>
            (Math.abs(c.num_min_age) >= minage && Math.abs(c.num_max_age) <= maxage && Math.abs(c.num_min_age) < Math.abs(c.num_max_age)) ||
            (Math.abs(c.num_min_age) >= minage && Math.abs(c.num_max_age) < maxage && Math.abs(c.num_min_age) == Math.abs(c.num_max_age))
        );
        let indtypes: string[] = new Array();
        for (let cube of cubes)
            if (indtypes.filter(ind => ind == cube.form_key).length == 0) indtypes.push(cube.form_key);
        for (let ind of indtypes) {
            const stolbik: ChartColumn = new ChartColumn({ cubes: cubes.filter(cube => cube.form_key == ind) });
            if (stolbik.cubes[0].form_type == 'A')
                epochchart.unshift(stolbik);
            else
                epochchart.push(stolbik);
        }
        return epochchart;
    }
}