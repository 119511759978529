import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonNode } from '../object-selection/super-form-control.component';
import { Config } from './app.service';
import { Composition } from '../_models/composition';
import { Observable } from 'rxjs';
// import { Observable } from 'rxjs';
// import { of } from 'rxjs/observable/of';
// import { catchError, map, tap } from 'rxjs/operators';

const constBabinRayon: Lexitem[] = [
  { text: "АЛЕУТСКАЯ ОСТРОВОДУЖНАЯ СИСТЕМА", code: "Алеутская островодужная система" },
  { text: "АМЕРАЗИЙСКО-ЧУКОТСКАЯ МЕГАПРОВИНЦИЯ", code: "Амеразийско-Чукотская мегапровинция" },
  { text: "ВОСТОЧНО-ЕВРОПЕЙСКАЯ ПЛАТФОРМА", code: "Восточно-Европейская платформа" },
  { text: "ЕВРАЗИЙСКАЯ ОКЕАНИЧЕСКАЯ МЕГАПРОВИНЦИЯ.", code: "Евразийская океаническая мегапровинция." },
  { text: "ЗАПАДНО-АРКТИЧЕСКАЯ МЕГАПРОВИНЦИЯ", code: "Западно-Арктическая мегапровинция" },
  { text: "ОХОТОМОРСКАЯ ПЛИТА", code: "Охотоморская плита" },
  { text: "ПРЕДУРАЛЬСКИЙ КРАЕВОЙ ПРОГИБ", code: "Предуральский краевой прогиб" },
  { text: "СИБИРСКАЯ ПЛАТФОРМА", code: "Сибирская платформа" },
  { text: "ТИХООКЕАНСКАЯ ОКЕАНИЧЕСКАЯ МЕГАПРОВИНЦИЯ", code: "Тихоокеанская океаническая мегапровинция" },
  { text: "УРАЛО-НОВОЗЕМЕЛЬСКАЯ СКЛАДЧАТАЯ ОБЛАСТЬ", code: "Урало-Новоземельская складчатая область" },
  { text: "ЯПОНОМОРСКАЯ ПЛИТА", code: "Япономорская плита" },
]

@Injectable({
  providedIn: 'root'
})
export class RepoService {

  private readonly genesis_available = ['3', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '19', '20', '27', '43'];

  // public static readonly ChokEpoch: Epoch[] = [{ title: 'Альпийский этап', tooltip: "Мел верхний - Неоген", minage: 0, maxage: 99.6, color: "rgb(201,168,53)" },
  // { title: 'Позднекиммерийсий подэтап', tooltip: "Юра средняя - Мел нижний ", minage: 99.6, maxage: 175.6, color: "rgb(49,170,161)" },
  // { title: 'Раннекиммерийский подэтап (индосинийский этап)', tooltip: "Триас- Юра нижняя", minage: 175.6, maxage: 251.0, color: "rgb(102,56,165)" },
  // { title: 'Герцинский этап', tooltip: "Карбон - Пермь", minage: 251.0, maxage: 359.2, color: "rgb(221,119,21)" },
  // { title: 'Каледонский этап (позднекаледонский подэтап)', tooltip: "Силур - Девон нижний ", minage: 359.2, maxage: 443.7, color: "rgb(113,141,5)" },
  // { title: 'Каледонский этап (раннекаледонский подэтап)', tooltip: "Кембрий средний - Ордовик", minage: 443.7, maxage: 510.0, color: "rgb(36,187,154)" },
  // { title: 'Байкальский этап', tooltip: "Рифей - Кембрий нижний", minage: 510.0, maxage: 1650.0, color: "rgb(210,58,37)" },
  // { title: 'Карельский этап', tooltip: "Архей - Протерозой нижний ", minage: 1650.0, maxage: 4500.0, color: "rgb(185,27,124)" }
  // ];

  public static readonly TectEpoch: Epoch[] = [
    { title: 'Альпийская', minage: 0.0, maxage: 40.0, color: "rgb(243,225,149)" },
    { title: 'Эвриканская', minage: 34.0, maxage: 56.0, color: "rgb(244,216,81)" },
    { title: 'Ларамийская', minage: 40.0, maxage: 80.0, color: "rgb(151,196,199)" },
    { title: 'Позднемезозойская (Сихоте-алиньская фаза)', minage: 94.0, maxage: 113.0, color: "rgb(174,211,178)" },
    { title: 'Позднемезозойская (Чукотская фаза)', minage: 101.0, maxage: 133.0, color: "rgb(107,179,178)" },
    { title: 'Позднемезозойская (Верхоянская фаза)', minage: 133.0, maxage: 164.0, color: "rgb(107,179,178)" },
    { title: 'Раннемезозойская', minage: 174.0, maxage: 237.0, color: "rgb(141,192,149)" },
    { title: 'Уральская', minage: 251.0, maxage: 307.0, color: "rgb(190,204,204)" },
    { title: 'Варисская', minage: 270.0, maxage: 340.0, color: "rgb(190,204,204)" },
    { title: 'Элсмирская', minage: 340.0, maxage: 375.0, color: "rgb(179,199,232)" },
    { title: 'Каледонская', minage: 390.0, maxage: 440.0, color: "rgb(146,181,211)" },
    { title: 'Раннекаледонская', minage: 450.0, maxage: 520.0, color: "rgb(105,150,192)" },
    { title: 'Байкальская', minage: 540.0, maxage: 870.0, color: "rgb(239,175,168)" },
    { title: 'Гренвильская (Свеконорвежская)', minage: 900.0, maxage: 1200.0, color: "rgb(250,213,230)" },
    { title: 'Готская', minage: 1520.0, maxage: 1750.0, color: "rgb(238,180,204)" },
    { title: 'Свекофенская', minage: 1750.0, maxage: 2100.0, color: "rgb(234,159,154)" },
    { title: 'Карельская', minage: 2100.0, maxage: 2300.0, color: "rgb(228,145,139)" },
    { title: 'Беломорская', minage: 2600.0, maxage: 2700.0, color: "rgb(218,186,201)" },
    { title: 'Кольская', minage: 2700.0, maxage: 3000.0, color: "rgb(215,181,198)" },
    { title: 'Белозерская', minage: 3000.0, maxage: 3500.0, color: "rgb(201,160,190)" }
  ];
  public static readonly DestrEpoch: Epoch[] = [
    { title: 'Кайнозой', minage: 0.0, maxage: 66.0, color: "rgb(246,222,153)" },
    { title: 'Юра - Мел', minage: 66.0, maxage: 201.3, color: "rgb(207,227,174)" },
    { title: 'Пермь - Триас', minage: 201.3, maxage: 299.0, color: "rgb(224,194,218)" },
    { title: 'Девон - Карбон', minage: 299.0, maxage: 419.0, color: "rgb(182,183,182)" },
    { title: 'Кембрий - Силур', minage: 419.0, maxage: 535.0, color: "rgb(232,180,141)" },
    { title: 'Неопротерозой (Поздний рифей - Венд)', minage: 542.0, maxage: 1030, color: "rgb(174,216,208)" },
    { title: 'Мезопротерозой (Ранний - Cредний рифей)', minage: 1030.0, maxage: 1650.0, color: "rgb(239,175,168)" },
    { title: 'Палеопротерозой (Ранний протерозой)', minage: 1650.0, maxage: 2500.0, color: "rgb(240,129,105)" },
    { title: 'Мезо-неоархей (Поздний архей)', minage: 2500.0, maxage: 3200.0, color: "rgb(241,132,157)" },
  ];
  public static readonly StratEpoch: Epoch[] = [];

  public static Genesis: [string, string];
  public static Strelrayon: Lexitem[];
  public static Shokrayon: Lexitem[];
  public static Babinrayon: Lexitem[];
  public static Formations: Composition[];

  ont12: Promise<JsonNode[]> = this.getData(Config.NadmUrl + '/ONT-12');
  ont13: Promise<JsonNode[]> = this.getData(Config.NadmUrl + '/ONT-13');
  ont14: Promise<JsonNode[]> = this.getData(Config.NadmUrl + '/ONT-14');
  ont20: Promise<JsonNode[]> = this.getData(Config.NadmUrl + '/ONT-20');
  shokRayon: Promise<JsonNode[]> = this.getShokRayon();
  strelRayon: Promise<JsonNode[]> = this.getStrelRayon();
  babinRayon: Promise<JsonNode[]> = this.getBabinRayon();
  genesis: Promise<JsonNode[]> = this.getGenesis();
  compositions: Promise<Composition[]> = this.getCompositions();

  constructor(private http: HttpClient) {
    // this.getCompositions().then(res => console.log(res));
  }
  getFont(): Promise<any> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');
    return this.http.get('./assets/fonts/GigGGK16_3.svg', { headers, responseType: 'text' }).toPromise();
  }

  getData(url: string): Promise<JsonNode[]> {
    return this.http.get(url)
      .toPromise()
      .then(
        res => {
          return res as JsonNode[];
        },
        err => {
          console.log(err);
          return null;
        });
  }
  getGenesis(): Promise<JsonNode[]> {
    return this.http.get(Config.WebAPIServiceUrl + Config.GenesislexiconUrl)
      .toPromise()
      .then(
        (data: [string, string]) => {
          let res = new Array<JsonNode>();
          for (let i in data) {
            if (this.genesis_available.includes(i))
              res.push({ Name: data[i], ID: i, ParentID: '' });
          }
          RepoService.Genesis = data;
          return res;
        },
        err => {
          console.log(err);
          return null;
        });
  }
  getShokRayon(): Promise<JsonNode[]> {
    return this.http.get(Config.WebAPIServiceUrl + Config.ShokRayonlexiconUrl)
      .toPromise()
      .then(
        (data: string[]) => {
          let res = new Array<JsonNode>();
          let lex = new Array<Lexitem>();
          for (let i in data) {
            res.push({ Name: data[i], ID: data[i], ParentID: '' });
            lex.push(new Lexitem(i, data[i]));
          }
          RepoService.Shokrayon = lex;
          return res;
        },
        err => {
          console.log(err);
          return null;
        });
  }
  async getStrelRayon(): Promise<JsonNode[]> {
    let data = await this.http.get(Config.WebAPIServiceUrl + Config.StrelRayonlexiconUrl).toPromise()
      .then(data => { return data; });
    let lex = new Array<Lexitem>();
    for (let i in data) {
      lex.push(new Lexitem(i, data[i]));
    }
    RepoService.Strelrayon = lex;
    return this.http.get(Config.StrelRayonHierarchyUrl)//(Config.WebAPIServiceUrl + Config.StrelRayonlexiconUrl)
      .toPromise()
      .then(
        (hierarchy) => {
          let res = new Array<JsonNode>();
          for (let i1 in hierarchy) {
            let l1 = hierarchy[i1];
            let temp1 = new Array<JsonNode>();
            for (let i2 in l1) {
              let l2 = l1[i2];
              let temp2 = new Array<JsonNode>(); // codes of Strelnikov Rayons
              for (let i3 in l2)
                temp2.push({
                  Name: data[l2[i3]],
                  ID: l2[i3],
                  ParentID: ''
                });
              temp1.push({
                Name: i2,
                ID: null,
                ParentID: '',
                Children: temp2
              });
            }
            res.push({
              Name: i1,
              ID: i1,
              ParentID: '',
              Children: temp1
            });
          }
          return res;
        },
        err => {
          console.log(err);
          return null;
        });
  }
  getBabinRayon(): Promise<JsonNode[]> {
    return this.http.get(Config.BabinRayonHierarchyUrl)//(Config.WebAPIServiceUrl + Config.StrelRayonlexiconUrl)
      .toPromise()
      .then(
        (hierarchy) => {
          let res = this.getNodes(hierarchy);
          let lex = new Array<Lexitem>();
          let i = 0;
          for (let p of res) {
            lex.push(new Lexitem(i + '', p.ID));
            i++;
            for (let c of p.Children) {
              lex.push(new Lexitem(i + '', c.ID));
              i++;
            }
          }
          RepoService.Babinrayon = lex;
          return res;
        },
        err => {
          console.log(err);
          return null;
        });
  }
  getNodes(data: any): Array<JsonNode> {
    let temp = new Array<JsonNode>();
    for (let s in data) {
      if (data[s] instanceof Object) {
        let item = constBabinRayon.find(i => i.text == s);
        temp.push({
          Name: s,
          ID: item ? item.code : s,
          ParentID: '',
          Children: this.getNodes(data[s])
        });
      }
    }
    return temp;
  }

  getCompositions(): Promise<Composition[]> {
    return this.http.get(Config.CompositionColor)
      .toPromise()
      .then(
        (data) => {
          let lex = new Array<Composition>();
          for (let i of data["Formations"])
            lex.push(i as Composition);
          RepoService.Formations = lex;
          return lex;
        },
        err => {
          console.log(err);
          return null;
        });
  }
  handleError(message: string, postparams: NadmParams[]) {
    console.log('--- message ---');
    console.log(message);
    console.log('--- postparams ---');
    console.log(postparams);
  }

  switchName(key: string): string {
    switch (key) {
      case "ont12": return "GENETIC_PROCESS_1";
      case "ont13": return "GENETIC_ENVIRONMENT_1";
      case "ont14": return "COMPOSITION_1";
      case "ont20": return "EARTH_MATERIAL_ID";
      default: return key;
    }
  }

  getNadmValues(params: NadmParams[]): Observable<any> {

    let url1 = 'http://test-iis.vsegei.ru/Kiji/wega_ggk_2500/web_content/nadm_dict.php?op=dict_search&id=20';
    // let url2 = 'http://test-iis.vsegei.ru/Kireychuk/cors.php';
    let url3 = Config.MapUrl + "nadm_dict.php?op=dict_search&id=20";

    return this.http.post(
      url1,
      JSON.stringify(params)
    );
  }

}



export interface NadmParams {
  property: string;// "EARTH_MATERIAL_ID" | "GENETIC_PROCESS_1" | "GENETIC_ENVIRONMENT_1" | "COMPOSITION_1";
  value: string;
}

export interface Epoch {
  title: string;
  // tooltip: string;
  minage: number;
  maxage: number;
  color: string;
}

export class Lexitem {
  code: string;
  text: string;
  constructor(code: string, text: string) {
    this.code = code;
    this.text = text;
  }
}
