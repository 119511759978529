import { Component, Output, EventEmitter, ViewChildren, QueryList, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SuperFormControlComponent } from './super-form-control.component';
import { RepoService, NadmParams } from '../_services/repo.service';
import { CompositionControlComponent } from './composition-control.component';
import { NouiFormatter, NouisliderComponent } from '../../../node_modules/ng2-nouislider';

import { FormControl, Validators, FormGroup } from '@angular/forms';
import { QueryParams } from '../_models/postParams';
import { ViewParams } from '../_models/viewParams';

@Component({
  selector: 'app-object-selection',
  templateUrl: './object-selection.component.html',
  styleUrls: ['./object-selection.component.scss','../corr-table/columns.component.scss']
})

export class ObjectSelectionComponent implements AfterViewInit {
  Repo = RepoService;
  @Output() selectionDone = new EventEmitter<{
    rayonType: string;
    rayonIds: string[];
    queryParams: QueryParams
  }>();

  abs_age: boolean = false;
  group_by_sub_unit: boolean = false;
  @ViewChildren("lith") lithologyParams: QueryList<SuperFormControlComponent>;
  @ViewChild("shokRayon") shokRayon: SuperFormControlComponent;
  @ViewChild("strelRayon") strelRayon: SuperFormControlComponent;
  @ViewChild("babinRayon") babinRayon: SuperFormControlComponent;
  @ViewChild("genesis") genesis: SuperFormControlComponent;
  @ViewChild(CompositionControlComponent) compositionParam: CompositionControlComponent;
  rayonType: 'shok' | 'strel' | 'babin' = 'shok';
  readonly star = ViewParams.star;

  absAgeType: [boolean, boolean, boolean, boolean] = [true, true, true, true];
  num_min_age: number = 0;
  num_max_age: number = 3500;
  agesTectEpoc: [number, number] = [0, 21];
  configTectSlider: any = {
    behaviour: 'drag',
    orientation: 'vertical',
    tooltips: [new TectFormatter(true, true), new TectFormatter(true, false)],
    connect: true,
    step: 1,
    margin: 1,
    start: 0,
    range: { min: 0, max: 20 }
  };
  agesDestrEpoc: [number, number] = [0, 10];
  configDestrSlider: any = {
    behaviour: 'drag',
    orientation: 'vertical',
    tooltips: [new DestrFormatter(true, true), new DestrFormatter(true, false)],
    connect: true,
    step: 1,
    margin: 1,
    start: 0,
    range: { min: 0, max: 9 }
  };

  min_age = new FormControl(this.num_min_age, [
    Validators.max(3500),
    Validators.min(0)//(this.num_max_age)
  ]);
  max_age = new FormControl(this.num_max_age, [
    Validators.max(3500),//(this.num_min_age),
    Validators.min(0)
  ]);
  lithologyValues: string[] = new Array<string>();
  lithologyShow: boolean = false;
  constructor(public repo: RepoService) {
  }
  ngAfterViewInit() {
    this.genesisAllToggle();
  }


  getRayonIds(): string[] {
    let res = new Array<string>();
    if (this.rayonType == 'shok') {
      res = this.shokRayon.getIds();
    }
    if (this.rayonType == 'strel') {
      res = this.strelRayon.getIds();
    }
    if (this.rayonType == 'babin') {
      res = this.babinRayon.getIds();
    }
    return res;
  }
  getQueryParams(): QueryParams {
    let res = new QueryParams();
    res.group_by_sub_unit = this.group_by_sub_unit;
    res.genesis = '(' + this.genesis.getIds().join(',').replace('3', '3,4,5') + ')';
    res.num_min_age = this.num_min_age + '';
    res.num_max_age = this.num_max_age + '';
    res.abs_age = this.abs_age;
    res.all_formations = this.compositionParam.getAllFormations();
    res.other_formations = this.compositionParam.getOtherFormations();
    if (!res.all_formations)
      res.formations = this.compositionParam.getIds();
    else
      res.formations = new Array<string>();
    res.minor_lithology = "";
    res.major_lithology = this.lithologyValues;
    res.abs_age_type = this.absAgeType;

    return res;
  }

  invalidValues() {
    const goalElement = document.getElementById('requiredparams');
    goalElement.scrollIntoView({ block: "center", behavior: "smooth" });
    this.genesis.highLight();
    if (this.rayonType == 'shok')
      this.shokRayon.highLight();
    if (this.rayonType == 'strel')
      this.strelRayon.highLight();
    if (this.rayonType == 'babin')
      this.babinRayon.highLight();
    if (!this.absAgeType.find(item => item == true))
      this.highLight('absAgeType');
  }

  done() {
    let rayonIds = this.getRayonIds();
    let queryParams = this.getQueryParams();
    if (rayonIds.length == 0 || queryParams.genesis == '()' || !this.absAgeType.find(item => item == true)) {
      return this.invalidValues();
    }
    if (queryParams.all_formations == false &&
      queryParams.other_formations == false &&
      queryParams.formations.length == 0
    ) {
      queryParams.all_formations = true;
      queryParams.other_formations = true;
    }

    this.selectionDone.emit({
      rayonType: this.rayonType,
      rayonIds: rayonIds,
      queryParams: queryParams
    });
  }

  setLithologyValues() {
    let params = new Array<NadmParams>();
    this.lithologyParams.forEach(item => {
      let value = item.getIds();
      if (value.length) {
        params.push({
          property: this.repo.switchName(item.key),
          value: value.join(',')
        });
      }
    });
    if (params.length == 0)
      this.lithologyValues = new Array<string>();

    else
      this.repo.getNadmValues(params).subscribe(
        (data) => {
          this.lithologyValues = data;
        }
      );
  }
  log() {
    this.setLithologyValues();
  }

  genesisAllSelected(): boolean {
    if (this.genesis.loading) return false;
    return this.genesis.treeControl.dataNodes.every(
      item => this.genesis.checklistSelection.isSelected(item)
    );
  }
  genesisPartiallySelected(): boolean {
    if (this.genesis.loading) return false;
    return this.genesis.treeControl.dataNodes.some(
      item => this.genesis.checklistSelection.isSelected(item)
    ) && !this.genesisAllSelected();
  }
  genesisAllToggle() {
    if (this.genesisAllSelected())
      this.genesis.checklistSelection.clear();
    else
      this.genesis.checklistSelection.select(...this.genesis.treeControl.dataNodes);
  }
  changeTectEpoc() {
    this.num_min_age = RepoService.TectEpoch[this.agesTectEpoc[0]].minage;
    this.num_max_age = RepoService.TectEpoch[this.agesTectEpoc[1] - 1].maxage;
  }
  changeDestrEpoc() {
    this.num_min_age = RepoService.DestrEpoch[this.agesDestrEpoc[0]].minage;
    this.num_max_age = RepoService.DestrEpoch[this.agesDestrEpoc[1] - 1].maxage;
  }

  highLight(divID: string) {
    const elem = document.getElementById(divID) as HTMLDivElement;
    elem.classList.add('invalid');
    setTimeout(() => { elem.classList.remove('invalid'); }, 1000);
  }
}

export class TectFormatter implements NouiFormatter {
  ranges: number[];
  RU: boolean;
  constructor(ru: boolean, upper) {
    this.RU = ru;
    this.ranges = RepoService.TectEpoch.map((epoch) => {
      if (upper) return epoch.minage;
      else return epoch.maxage;
    });
    if (upper)
      this.ranges.push(0);
    else {
      this.ranges.reverse();
      this.ranges.push(0);
      this.ranges.reverse();
    }
  }
  to(value: number): string {
    value = Math.round(value);
    let mlnyears = this.RU ? ' Ма' : ' Ma';
    let output = this.ranges[value];
    return output + mlnyears;
  }
  from(value: string): number {
    return Number(value.split(" ")[0]);
  }
}
export class DestrFormatter implements NouiFormatter {
  ranges: number[];
  RU: boolean;
  constructor(ru: boolean, upper) {
    this.RU = ru;
    this.ranges = RepoService.DestrEpoch.map((epoch) => {
      if (upper) return epoch.minage;
      else return epoch.maxage;
    });
    if (upper)
      this.ranges.push(0);
    else {
      this.ranges.reverse();
      this.ranges.push(0);
      this.ranges.reverse();
    }
  }
  to(value: number): string {
    value = Math.round(value);
    let mlnyears = this.RU ? ' Ма' : ' Ma';
    let output = this.ranges[value];
    return output + mlnyears;
  }
  from(value: string): number {
    return Number(value.split(" ")[0]);
  }
}