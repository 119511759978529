import { Cube } from './cube';

export class Column {
    cubes: Cube[];
    title: string;
    maxN: number;
    startX: number;
    finalX: number;

    constructor(props: { cubes: Cube[], title: string }) {
        Object.assign(this, props)
    }
    Sort() {
        let temp: Cube[] = new Array();
        let n = 0;
        this.cubes.sort((a, b) => {
            if (a.ageMaxY > b.ageMaxY) return 1;
            if (a.ageMaxY < b.ageMaxY) return -1;
            return 0;
        })
        while (this.cubes.find(cube => cube.line == -1) != undefined) {
            this.cubes.forEach(cube => {
                if (cube.line == -1) {
                    if (temp.length == 0) {
                        cube.line = n;
                        temp.push(cube);
                    }
                    else {
                        if (!(temp[temp.length - 1].ageMaxY > cube.ageMinY)) {
                            cube.line = n;
                            temp.push(cube);
                        }
                    }
                }
            });
            temp = new Array();
            n++; this.maxN = n;
            if (n > 100) break;
        }
        this.cubes.sort((a, b) => {
            if (a.line > b.line) return -1;
            if (a.line < b.line) return 1;
            return 0;
        })
    }
}
export class ColumnHeader {
    width: number;
    title: string;
}
