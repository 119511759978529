import { NgModule, APP_INITIALIZER } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AppService } from './_services/app.service';
import { RepoService } from './_services/repo.service';
import { CubesService } from './_services/cubes.service';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialAppModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { ObjectSelectionComponent } from './object-selection/object-selection.component';
import { SuperFormControlComponent } from './object-selection/super-form-control.component';
import { StratAgesComponent } from './corr-table/strat-ages.component';
import { ColumnsComponent } from './corr-table/columns.component';
import { ChartsComponent } from './charts/charts.component';
import { SingleChartComponent } from './charts/single-chart.component';
import { ListComponent } from './list/list.component';
import { HighlightPipe } from './_pipes/highlight.pipe';
import { MatTreeModule, MatIconModule, MatButtonModule, MatInputModule, MatSelectModule, MatRadioModule, MatCardModule } from '@angular/material';
import { CompositionControlComponent } from './object-selection/composition-control.component';
import { NouisliderModule } from 'ng2-nouislider';
import { StratService } from './_services/strat.service';
import { ChartHeightDirective } from './_directives/chart-get-height.directive';
import { WelcomeComponent } from './welcome/welcome.component';
import { MessageBarComponent } from './message-bar/message-bar.component';
import { CutTextPipe } from './_pipes/cut-text.pipe';
import { InsideEpochPipe } from './_pipes/inside-epoch.pipe';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { BarComponent } from './charts/bar.component';

export function app_Init(appService: AppService) {
  return () => appService.getConfigs().then(res => {
    return;// appService.getLex();
  });
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ObjectSelectionComponent,
    SuperFormControlComponent,
    StratAgesComponent,
    ColumnsComponent,
    ChartsComponent,
    SingleChartComponent,
    ListComponent,
    HighlightPipe,
    CompositionControlComponent,
    ChartHeightDirective,
    WelcomeComponent,
    MessageBarComponent,
    CutTextPipe,
    InsideEpochPipe,
    ImageDialogComponent,
    BarComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialAppModule,
    FormsModule,
    NouisliderModule,
    HttpClientModule, MatTreeModule, MatIconModule, MatButtonModule, MatInputModule, MatSelectModule, MatRadioModule, MatCardModule, ReactiveFormsModule
  ],
  providers: [
    AppService,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: APP_INITIALIZER, useFactory: app_Init, deps: [AppService], multi: true },
    RepoService,
    CubesService,
    StratService,

  ],
  entryComponents: [
    ImageDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
