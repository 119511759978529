import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Column } from '../_models/column';
import { Cube } from '../_models/cube';
import { RepoService, Epoch } from '../_services/repo.service';
import { Config } from '../_services/app.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @Input() columns: Column[];
  @Input() selcubes: Cube[];
  @Output() scrollToCube = new EventEmitter<{ colN: number, cubeN: number }>();
  Config=Config;
  constructor() { }

  ngOnInit() {
  }
  isSelected(cube: Cube) {
    if (this.selcubes.filter(c => c == cube).length != 0) {
      return 'lightgrey';
    }
    else {
      return 'white';
    }
  }
  export2xls(event: MouseEvent) {
    let table = document.getElementById("seltable").cloneNode(true) as HTMLTableElement;

    var blob = new Blob(['<table>', table.innerHTML, '</table>'], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
    });
    var xlsUrl = URL.createObjectURL(blob);
    var downloadLink = document.createElement("a");
    downloadLink.href = xlsUrl;
    downloadLink.download = "table.xls";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  emitScrollToCube(i, j) {
    this.scrollToCube.emit({ colN: j, cubeN: i });
  }
}
