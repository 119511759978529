import { StratService } from '../_services/strat.service';
import { RepoService } from '../_services/repo.service';

export class Cube {
    // rayon: string;
    ageMinTxt: string;
    ageMaxTxt: string;
    ageMinY: number;
    ageMaxY: number;
    line: number;
    genesisTXT: string[];
    // indKey: string;
    mapKey: string;
    color: string;
    // type: string;

    // from json
    subUnit: string;
    rayonCode: number;
    saMin: number;
    saMax: number;
    sumArea: number;
    countArea: number;
    l_code: number;
    ind_code: string;
    form_key: string;  //код состава, полученный из Ind_code
    form_type: string; //тип обстановки: формирования или деструкции 

    rus_txt: string;
    map_index: string;
    major_lith: string;
    minor_lith: string;
    num_min_age: number;
    num_max_age: number;
    abs_min_age: number[];
    abs_max_age: number[];
    genesis: number[];
    objectIds: number[];
    samples: Sample[];
    globSamples: GlobSample[];

    hasAbsAge = (): boolean=> {return this.abs_min_age.length > 1 || this.abs_min_age[0] != 0 || this.abs_min_age.length > 1 || this.abs_min_age[0] != 0;}
    hasSamples = (): boolean=> {return this.samples.length>0}
    hasGlobSamples = (): boolean=> {return this.globSamples.length>0}
    AssignJson(data:any, rayonCode: number):void{
        Object.assign(this, data)
        this.subUnit = this.subUnit || '-';
        this.ageMinTxt = StratService.Titles[this.saMin];
        this.ageMaxTxt = StratService.Titles[this.saMax];
        // this.rayon = title;
        this.rayonCode = rayonCode;
        let yvalues = StratService.MinMaxAgeY(this.ageMinTxt, this.ageMaxTxt);
        this.ageMinY = yvalues[0];
        this.ageMaxY = yvalues[1];
        let f = RepoService.Formations.find(item => item.key == this.form_key);
        this.mapKey = f ? f.mapkey : '';
        this.color = f ? f.color : 'rgb(240,240,240)';
        this.line = -1;
        this.genesisTXT = new Array();
        this.genesis.forEach(g => {
          this.genesisTXT.push(RepoService.Genesis[g + '']);
        });
    }
}
export class Sample {
    // from json
    objectID: number;
    sampleID: number;
    subUnit: string;
    saMin: string;
    saMax: string;
    mapUnit: string;
    datingObject: string;
}


export class GlobSample
{
    objectID: number;
    sampleID: string;
    subUnit: string;
    age_235U: string;
    age_238U: string;
    age_206PB: string
    journalName: string;
    pubYear: string; 
}