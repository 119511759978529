import { Component, OnInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { QueryParams } from '../_models/postParams';
import { CubesService } from '../_services/cubes.service';
import { Cube } from '../_models/cube';
import { RepoService } from '../_services/repo.service';
import { Column } from '../_models/column';
import { StratService, sNode } from '../_services/strat.service';
import { ColumnsComponent } from '../corr-table/columns.component';
import { ChartsComponent } from '../charts/charts.component';
import { RayonCharts, ChartHelper } from '../_models/diagramclasses';
import { ViewParams } from '../_models/viewParams';
import { Config } from '../_services/app.service';
import { svgHelper } from '../_models/svgHelper';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Rayon } from '../_models/rayon';
import { MatDialog } from '@angular/material';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';

const snackconfig: MatSnackBarConfig = {
  verticalPosition: 'bottom',
  horizontalPosition: 'right'
}
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  activeTab: 'obj-select' | 'corr-table' | 'charts' | 'list' | 'welcome' = 'obj-select';
  totalWidth: number = 0;
  columns: Column[] = new Array<Column>();
  strelnikoffmode = false;
  selectedcubes: Cube[] = new Array();
  loading = false;
  tasks: number = 0;
  fonts;
  map: Window;// = window.opener;
  timer;

  @ViewChild(ChartsComponent) charts: ChartsComponent;
  @ViewChild(ColumnsComponent) maintable: ColumnsComponent;
  @ViewChild('snackBarTemplate') snackBarTemplate: TemplateRef<any>;
  rayonsProgress: Rayon[] = [];


  constructor(
    private repo: RepoService,
    private service: CubesService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit() {
    (async () => {
      this.fonts = await this.repo.getFont();
      let parser = new DOMParser();
      let fonts = parser.parseFromString(this.fonts, "application/xml");
      let glyphs = fonts.getElementsByTagName("glyph");
      this.charts.glyphs = glyphs;
    })();
    this.zoommode('normal');
    if (document.referrer.includes("wega")) this.map = window.opener;
  }


  public dismissSnackbar(): void {
    this.snackBar.dismiss();
  }


  // ******************
  // *** GET CUBES ***
  // ******************
  // #region 
  getColumns(event) {
    const type: 'shok' | 'strel' | 'babin' = event.rayonType;
    const ids: string[] = event.rayonIds;
    this.rayonsProgress = [];
    let pars: QueryParams = event.queryParams;
    this.strelnikoffmode = pars.group_by_sub_unit;
    this.columns = new Array<Column>();
    this.snackBar.openFromTemplate(this.snackBarTemplate, snackconfig);
    let titles: { [id: string]: string; } = {};
    let codes: { [id: string]: number; } = {};
    if (type == 'shok') {
      ids.forEach(id => {
        titles[id] = id;
        codes[id] = Number(RepoService.Shokrayon.find(r => r.text == id).code);
      });
      pars.shok_rayons = ids.map(id => "'" + id.replace("'", "''") + "'");
    }
    if (type == 'strel') {
      ids.forEach(id => {
        let myitem = RepoService.Strelrayon.find(item => item.code == id);
        titles[id] = myitem ? myitem.text : "???";
        codes[id] = Number(id);
      });
      pars.strel_rayons = ids.map(id => "'" + id.replace("'", "''") + "'");
    }
    if (type == 'babin') {
      ids.forEach(id => {
        let myitem = RepoService.Babinrayon.find(item => item.text == id);
        titles[id] = myitem ? myitem.text : id;
        codes[id] = myitem ? RepoService.Babinrayon.indexOf(myitem) : -1;
      });
      pars.babin_rayons = ids.map(id => "'" + id + "'");
    }
    ids.forEach(id => {
      this.rayonsProgress.push({ id: codes[id] + '', title: titles[id], loading: true, count: undefined });
      this.addTask(true, '', -1);
    });

    let cubes: Cube[] = [];

    this.service.getColumn(pars).subscribe((data) => {
      for (let n in data) {
        let cube: Cube = new Cube();
        cube.AssignJson(data[n], codes[data[n].rayonCode]);
        cubes.push(cube);
      }
      ids.forEach(id => {
        let column = new Column({
          cubes: cubes.filter(c => c.rayonCode == codes[id]),
          title: titles[id]
        });
        if (column.cubes.length) {
          column.Sort();
          if (this.columns.length == 0) {
            column.startX = 0;
            column.finalX = column.maxN + 1;
          }
          else {
            column.startX = this.columns[this.columns.length - 1].finalX;
            column.finalX = column.startX + column.maxN + 1;
          }
          this.maintable.fullWidth = column.finalX * this.maintable.cubesWidth;
          const table = document.getElementById('content') as HTMLDivElement;
          table.style.width = String(this.maintable.fullWidth + 800) + 'px';
          this.columns.push(column);
          this.addTask(false, codes[id] + '', column.cubes.length);
        }
        else
          this.addTask(false, codes[id] + '', 0);
      });
    },
      error => {
        this.service.handleError(error, pars);
        this.addTask(false, '', -1);
      }
    );
  }
  addTask(increment: boolean, id: string, count: number) {
    this.tasks = increment ? this.tasks + 1 : this.tasks - 1;
    if (!increment) {
      let item = this.rayonsProgress.find(item => item.id == id);
      item.loading = false;
      item.count = count;
    }
    this.loading = this.tasks != 0;
    if (this.loading == false) this.afterAllLoading();
  }
  afterAllLoading() {
    let charts: RayonCharts[] = ChartHelper.createChart(this.columns);
    let maxvalue = 0;
    for (let rch of charts) {
      rch.SetValues();
      if (maxvalue < rch.maxvalue) maxvalue = rch.maxvalue;
    }
    maxvalue = Math.ceil(maxvalue / 100) * 100;
    let linearr = new Array();
    for (let i = 0; i < 6; i++) {
      linearr.push(i * maxvalue / 5);
    }
    this.charts.charts = charts;
    this.charts.maxvalue = maxvalue;

    setTimeout(() => {
      this.charts.updateCharts();
    }, 500);
    setTimeout(() => {
      this.dismissSnackbar();
    }, 3000);
  }
  // #endregion 

  // ******************
  // *** EVENTS ***
  // ******************
  // #region 
  cubeSelected(cube: Cube) { // event emmited from corr-table
    let index = this.selectedcubes.indexOf(cube);
    if (index == -1) this.selectedcubes.push(cube);
    else this.selectedcubes.splice(index, 1);
  }
  scrollToCube(e) {
    const j = e.colN;
    const i = e.cubeN;
    const id = 'c' + j + 'r' + i;
    this.activeTab = 'corr-table';
    const goalElement = document.getElementById(id);
    const cube = this.columns[j].cubes[i];
    let index = this.selectedcubes.indexOf(cube);
    if (index == -1) this.selectedcubes.push(cube);
    setTimeout(() => { goalElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" }); }, 500);
  }

  periodSelected(node: sNode) { // clicked strat age
    let ymin = node.y + node.h;
    let ymax = node.y;
    let filteredcubes: Cube[] = new Array();
    let isselect = true;
    this.columns.forEach(col => {
      col.cubes.forEach(cube => {
        let cubeymin = cube.ageMinY;
        let cubeymax = cube.ageMaxY;
        let cond1 = cubeymin >= ymax && cubeymin < ymin;
        let cond2 = cubeymax > ymax && cubeymax <= ymin;
        let cond3 = cubeymax > ymax && cubeymin < ymin;
        if (cond1 || cond2 || cond3) {
          filteredcubes.push(cube);
          if (this.selectedcubes.find(c => c == cube) == undefined) isselect = false;
        }
      })
    });
    if (isselect) {
      this.selectedcubes = this.selectedcubes.filter(c => filteredcubes.find(k => k == c) == undefined);
    }
    else {
      filteredcubes.forEach(c => this.selectedcubes.push(c));
    }
  }


  // #endregion 

  // ******************
  // *** EXPORT ***
  // ******************
  // #region 

  export2img() {
    // Cubes position
    let min = 118; let max = 0;
    if (this.columns.length)
      this.columns.forEach(col => {
        col.cubes.forEach(cube => {
          min = Math.min(cube.ageMinY, min);
          max = Math.max(cube.ageMaxY, max);
        });
      });
    else {
      min = 0; max = 118;
    }
    // Dialog
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      width: '400px',
      disableClose: true,
      position: { left: 'calc(50vw - 100px)' },
      data: { min: min, max: max }
    });
    dialogRef.afterClosed().subscribe((dialogresult: any[]) => {
      if (!dialogresult) return;
      console.log(dialogresult);
      // Params
      const h = 118 * ViewParams.coeffY + ViewParams.hdHeight;
      const sw = 5 * ViewParams.coeffX; // strat width
      const fw = this.maintable.fullWidth + sw; //full width
      const title = dialogresult[0];
      const cropTop = (dialogresult[1].y) * ViewParams.coeffY;
      const cropBottom = (118 - (dialogresult[2].y + dialogresult[2].h)) * ViewParams.coeffY;
      console.log(cropTop, ', ', cropBottom);
      // Agestitles
      const stratheader = document.getElementById('stratheader').innerHTML;
      const strattable = document.getElementById('strattable').innerHTML;
      // Cubes table    
      const cubesheader = document.getElementById('cubesheader').innerHTML;

      let svg2 = document.getElementById('cubescomp').cloneNode(true) as HTMLElement;
      let textelems = svg2.getElementsByTagName('text');
      for (let i = textelems.length - 1; i >= 0; i--) {
        let text = textelems[i];
        if (text.getAttribute('font-family') == "GigGGK16_3" && text.innerHTML.length > 0) {
          let paths = svgHelper.replaceTextByPath(text, this.charts.glyphs);
          for (let path of paths)
            text.parentElement.insertBefore(path, text);
          text.remove();
        }
      }
      let cubescomp = svg2.innerHTML.replace(new RegExp('class="galo"', 'g'), 'stroke="#FFFFFF" stroke-width="2"');
      let result = `<svg xmlns="http://www.w3.org/2000/svg" width="${fw}" height="${h - cropTop - cropBottom}">                    
                      <rect width="${fw}" height="${h - cropTop - cropBottom}" x="0" y="0" fill="white" stroke="rgb(50,50,50)"></rect>
                      <g transform="translate(0,${0 - cropTop})"> ${strattable} </g>
                      <g transform="translate(${sw},${ViewParams.hdHeight - cropTop})"> ${cubescomp} </g>
                      <g> ${stratheader} </g>
                      <g transform="translate(${sw},0)"> ${cubesheader} </g>
                    </svg>`;
      svgHelper.svg2Img(result, title, h - cropTop - cropBottom, fw);
    });

  }

  // #endregion 

  // ******************
  // *** imgbuttons ***
  // ******************
  // #region
  zoommode(mode) {
    let buttonnormal = document.getElementById("modenormal") as HTMLButtonElement;
    let buttoncompact = document.getElementById("modecompact") as HTMLButtonElement;
    if (mode == 'normal') {
      this.maintable.zoommode = 1;
      buttonnormal.disabled = true;
      buttoncompact.disabled = false;
    }
    else {
      this.maintable.zoommode = 0.7;
      buttonnormal.disabled = false;
      buttoncompact.disabled = true;
    }
    let w = Math.round(ViewParams.cubesWidth * this.maintable.zoommode);
    this.maintable.cubesWidth = w;
    if (this.columns.length)
      this.maintable.fullWidth = this.columns[this.columns.length - 1].finalX * this.maintable.cubesWidth;
    this.maintable.cubesCharcount = Math.round(w / 6) - 4;
  }
  selectAll() { // clicked selectAll
    this.selectedcubes = new Array();
    this.columns.forEach(col => {
      col.cubes.forEach(cube => {
        this.selectedcubes.push(cube);
      })
    });
  }
  deselectAll() { // clicked deselectAll
    this.selectedcubes = new Array();
  }
  @HostListener('window:message', ['$event'])
  selectFromMap(event) {
    if (event.data && event.data.type == "map") {
      const ids: string[] = event.data.ids as string[];
      console.log(ids);
      // this.selectOnMap({ type: 'prs_array', id: probIds.map(p => { return p.nprob }) })
    }
  }
  selectOnMap() {
    if (!this.map || this.map.closed) {
      this.map = window.open(Config.MapUrl);
    }
    let res = new Array();
    this.selectedcubes.forEach(cube =>
      cube.objectIds.forEach(id =>
        res.push(id))
    );
    const msg = {
      type: 'corrTable',
      params: res
    }
    const sendCubes = () => { setTimeout(() => { this.map.postMessage(msg, '*'); }, 1000) };
    this.map.focus();
    sendCubes();
    // this.map.addEventListener('load', sendCubes);
    // else {
    //   this.map.removeEventListener('load', sendCubes);
    // }
  }
  // #endregion 

  // ******************
  // *** EXTRA ***
  // ******************
  // #region 

  openWelcome() {
    document.getElementById("welcomescreensaver").style.display = "block";
    document.getElementsByTagName("body").item(0).style.overflow = "hidden";
  }

  // Close the full screen search box 
  closeWelcome() {
    document.getElementById("welcomescreensaver").style.display = "none";
    document.getElementsByTagName("body").item(0).style.overflow = "auto";
  }

  // #endregion 

}



  // ******************
  // ***  ***
  // ******************
  // #region



  // #endregion 