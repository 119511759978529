import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from './app.service';
import { QueryParams } from '../_models/postParams';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cube } from '../_models/cube';
import { Column } from '../_models/column';
import { StratService } from './strat.service';
import { RepoService } from './repo.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CubesService {

  constructor(private http: HttpClient) { }

  getColumn(queryParams: QueryParams) {
    let url = Config.WebAPIServiceUrl + Config.CubesUrl;
    return this.http.post(url, queryParams, httpOptions)
      
  }

  handleError(message: string, postparams: QueryParams) {
    console.log('--- ' + message + ' ---');
    console.log(postparams);
  }
}