import { Component, Input, ChangeDetectorRef, ElementRef, HostListener, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { of as observableOf } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { AppService } from '../_services/app.service';

export interface JsonNode {
  Name: string;
  ID: string;
  ParentID: string;
  Children?: JsonNode[];
}
export interface FlatTreeNode {
  Name: string;
  ID: string;
  level: number;
  expandable: boolean;
  badge: number;
}

@Component({
  selector: 'app-super-form-control',
  templateUrl: './super-form-control.component.html',
  styleUrls: ['./super-form-control.component.scss']
})
export class SuperFormControlComponent implements AfterViewInit {
  /** Vocabulary URL **/
  @Input() key: string;
  @Input() empty: string;
  @Input() leafsonly: boolean = false;
  @Input() search: boolean;
  @Input() data: Promise<JsonNode[]>;
  loading: boolean = true;
  treeShow: boolean = false;
  width: number;
  @Output() smthchanged = new EventEmitter();

  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<FlatTreeNode>;

  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<JsonNode, FlatTreeNode>;

  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<JsonNode, FlatTreeNode>;

  /** Match string **/
  matchString: string = "";

  /** The selection for checklist */
  checklistSelection = new SelectionModel<FlatTreeNode>(true);


  constructor(private _elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private service: AppService) {

    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren);

    this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
  }
  ngAfterViewInit() {
    this.onResize('');
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    (async () => {
      this.dataSource.data = await this.data;
      this.loading = false;
    })();
  }
  getIds(): string[] {
    let ids: string[] = new Array<string>();

    this.checklistSelection.selected.forEach(item => {
      // console.log(item);
      if (item.ID)
        if (!this.leafsonly)
          ids.push(item.ID);
        else
          if (!item.expandable)
            ids.push(item.ID);
    });
    // console.log(ids);
    return ids//.join(',');
  }

  /** Transform the data to something the tree can read. */
  transformer(node: JsonNode, level: number) {
    return {
      Name: node.Name,
      ID: node.ID,
      level: level,
      expandable: !!node.Children && !!node.Children.length,
      badge: -1
    };
  }

  /** Get the level of the node */
  getLevel(node: FlatTreeNode) {
    return node.level;
  }

  /** Get whether the node is expanded or not. */
  isExpandable(node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: JsonNode) {
    return observableOf(node.Children);
  }

  /** Whether the node contains match string.*/
  noMatch = (index: number, node: FlatTreeNode) => {
    return node.badge == 0;
  }

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: FlatTreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    if (!descendants.length) {
      return this.checklistSelection.isSelected(node);
    }
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FlatTreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    if (!descendants.length) {
      return false;
    }
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Select/deselect all the descendants node */
  nodeSelectionToggle(node: FlatTreeNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    if (descendants.length == 0) {
      this.smthchanged.emit(null);
      return;
    }
    this.descendantsAllSelected(node)
      ? this.checklistSelection.deselect(...descendants, node)
      : this.checklistSelection.select(...descendants, node);
    this.changeDetectorRef.markForCheck();
    this.smthchanged.emit(null);
  }

  /** Deselect all */
  nodeSelectionClear(): void {
    this.checklistSelection.clear();
    this.changeDetectorRef.markForCheck();
    this.smthchanged.emit(null);
  }

  matchStringChanged(s: string): void {
    this.matchString = s;
    if (s == "") {
      this.treeControl.dataNodes.forEach(node => {
        node.badge = -1
      });
    }
    else {
      this.treeControl.dataNodes.forEach(node => {
        this.badgeCalculate(node);
        this.treeControl.toggle(node);
        this.treeControl.toggle(node);
      });
    }
  }
  badgeCalculate(node: FlatTreeNode): number {
    const descendants = this.treeControl.getDescendants(node);
    if (!descendants.length) {
      node.badge = node.Name.includes(this.matchString) ? 1 : 0;
      return node.badge;
    }
    let includes = 0;
    descendants.forEach(childnode => {
      if (childnode.level - node.level == 1)
        includes += this.badgeCalculate(childnode);
    });
    node.badge = includes;
    return includes;
  }

  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside)
      this.treeShow = false;
    else
      if (!this.width)
        this.onResize('');
  }
  @HostListener('window:resize', ['$event']) onResize(event) {
    const elem = document.getElementsByClassName('ddheader')[0] as HTMLDivElement;
    this.width = elem.offsetWidth;// - 2;
  }
  highLight() {
    if (this.empty && this.checklistSelection.selected.length == 0) {
      const elem = this._elementRef.nativeElement.getElementsByClassName('ddheader')[0] as HTMLDivElement;
      elem.classList.add('invalid');
      setTimeout(() => { elem.classList.remove('invalid'); }, 1000);
    }
  }
}
