import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Cube } from '../_models/cube';
import { Column } from '../_models/column';
import { ViewParams } from '../_models/viewParams';
import { svgHelper } from '../_models/svgHelper';
import { Config } from '../_services/app.service';

const svgns = 'http://www.w3.org/2000/svg';
const xlink = 'http://www.w3.org/1999/xlink';
@Component({
  selector: 'app-columns',
  templateUrl: './columns.component.html',
  styleUrls: ['./columns.component.scss']
})
export class ColumnsComponent implements OnInit {
  @Input() columns: Column[];
  @Input() selcubes: Cube[];
  @Input() strelnikoffmode: boolean;
  @Output() cubeSelected = new EventEmitter<Cube>();

  readonly coeffY = ViewParams.coeffY;
  readonly rowsnumber = ViewParams.rowsnumber;
  readonly rowsarr = Array(this.rowsnumber);
  readonly tooltipWidth = ViewParams.tooltipWidth;
  readonly tooltipHeight = ViewParams.tooltipHeight;
  readonly linespacing = ViewParams.linespacing;
  readonly star = ViewParams.star;
  readonly cgrey = ViewParams.darkgrey;
  readonly cred = ViewParams.darkred;
  readonly hdHeight = ViewParams.hdHeight;
  readonly rowarrEpoch = [32, 51, 62, 78, 89, 100, 111];

  cubesWidth: number;
  cubesCharcount: number = 13;
  zoommode: number = 1;
  fullWidth: number = 0;

  constructor() {
    this.cubesWidth = Math.round(ViewParams.cubesWidth * this.zoommode);
    this.cubesCharcount = Math.round(this.cubesWidth / 6) - 4;
    this.columns = new Array<Column>();
  }

  ngOnInit() {
  }



  dValue(i): string {
    if (this.columns.length == 0) return '';
    return "M 0," + (i + 1) * this.coeffY + " L " + this.columns[this.columns.length - 1].finalX * this.cubesWidth + "," + (i + 1) * this.coeffY;
  }

  isSelected(cube) {
    if (this.selcubes.filter(c => c == cube).length == 0) return 'deselectedcube';
    else return 'selectedcube';
  }
  tongleselect(i, j) {
    let column: Column = this.columns[j];
    this.cubeSelected.emit(column.cubes[i]);
  }

  showPopup(i, j, x, y) {

    let padding = this.linespacing;
    let xp = x + padding;
    let column: Column = this.columns[j];
    let cube = column.cubes[i];
    let id = 'c' + j + 'g' + i;
    let g = document.getElementById(id);

    g.parentElement.appendChild(g);

    let container = document.createElementNS(svgns, "g");
    //container.setAttributeNS(null, "id", "temp" + i);

    if (g.getElementsByTagNameNS(svgns, 'g').length != 0) {
      g.getElementsByTagNameNS(svgns, 'g')[0].setAttributeNS(null, "visibility", "visible");
      return;
    }
    let text = document.createElementNS(svgns, "text");
    text.setAttributeNS(null, "x", x);
    text.setAttributeNS(null, "y", y);
    text.setAttributeNS(null, "text-anchor", "start ");
    let dy = 1;
    let dx = 1;
    if (this.strelnikoffmode) {
      // ind_code
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "ind_code:", true, 0));
      dy++;
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.ind_code, false, padding));
      dy++;
      // l_code
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "l_code:", true, 0));
      dy++;
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.l_code, false, padding));
      dy++;
      // ageMinTxt
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "ageMinTxt:", true, 0));
      dy++;
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.ageMinTxt, false, padding));
      dy++;
      // ageMaxTxt
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "ageMaxTxt:", true, 0));
      dy++;
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.ageMaxTxt, false, padding));
      dy++;
    }
    // subUnit
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Комплекс:", true, 0));
    dy++;
    let subUnitCount = 0
    for (let item of cube.subUnit.split(',')) {
      if (subUnitCount > 5) {
        text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, '...', false, padding));
        dy++;
        break;
      }
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, item, false, padding));
      dy++;
      subUnitCount++;
    }
    // countArea
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Общ. кол-во:", true, 0));
    dy++;
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.countArea, false, padding));
    dy++;
    // sumArea
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Общ. площ.:", true, 0));
    dy++;
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.sumArea, false, padding));
    dy++;
    // genesis
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Генезис:", true, 0));
    dy++;
    cube.genesisTXT.forEach(item => {
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, item, false, padding));
      dy++;
    });

    // rus_txt
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Описание:", true, 0));
    dy++;
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.rus_txt, false, padding));
    dy++;

    // major_lith
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Литология(major):", true, 0));
    dy++;
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.major_lith, false, padding));
    dy++;
    // minor_lith
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Литология(minor):", true, 0));
    dy++;
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.minor_lith, false, padding));
    dy++;

    // num_min_age: number;
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Мин. возраст(теор):", true, 0));
    dy++;
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.num_min_age + ' Ma', false, padding));
    dy++;
    // num_max_age: number;
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Макс. возраст(теор):", true, 0));
    dy++;
    text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, cube.num_max_age + ' Ma', false, padding));
    dy++;
    // abs_min_age: number[];
    if (cube.abs_min_age.length > 1 || cube.abs_min_age[0] != 0) {
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Мин. возраст(лаб):", true, 0));
      dy++;
      cube.abs_min_age.forEach(item => {
        text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, item + ' Ma', false, padding));
        dy++;
      });
    }
    // abs_max_age: number[];
    if (cube.abs_max_age.length > 1 || cube.abs_max_age[0] != 0) {
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Макс. возраст(лаб):", true, 0));
      dy++;
      cube.abs_max_age.forEach(item => {
        text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, item + ' Ma', false, padding));
        dy++;
      });
    }

    container.appendChild(text);

    // samples
    if (cube.samples.length > 0) {
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Геохронололичекие анализы проб:", true, 0));
      dy++;
    }
    cube.samples.forEach(item => {
      let linktxt = item.datingObject + ' ' + item.mapUnit;
      if (linktxt == ' ') linktxt = item.sampleID + '';
      container.appendChild(svgHelper.createxlink(xp, y, dy * this.linespacing, linktxt, padding, Config.GeochronServiceUrl + item.sampleID));
      let tempdx = container.lastChild.textContent.length + 5;
      dx = tempdx < dx ? dx : tempdx;
      dy++;
    });
    // global samples
    if (cube.globSamples.length > 0) {
      text.appendChild(svgHelper.createtspan(xp, y, dy * this.linespacing, "Опубликованные данные:", true, 0));
      dy++;
    }
    cube.globSamples.forEach(item => {
      let linktxt = item.journalName + ' ' + item.pubYear;
      if (linktxt == ' ') linktxt = item.sampleID + '';
      container.appendChild(svgHelper.createxlink(xp, y, dy * this.linespacing, linktxt, padding, Config.GeochronAtlasServiceUrl + item.sampleID));
      let tempdx = container.lastChild.textContent.length + 5;
      dx = tempdx < dx ? dx : tempdx;
      dy++;
    });

    for (let i in text.childNodes)
      if (text.childNodes[i].textContent != undefined) {
        let tempdx = text.children[i].textContent.length;
        dx = tempdx < dx ? dx : tempdx;
      }
    // rect
    let rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    rect.setAttributeNS(null, "x", x);
    rect.setAttributeNS(null, "y", y);
    rect.setAttributeNS(null, "height", String(dy * this.linespacing));
    rect.setAttributeNS(null, "width", String(Math.floor(7.3 * dx) + 3 * padding));
    rect.setAttributeNS(null, "fill", 'rgb(255,255,255)');
    rect.setAttributeNS(null, "rx", "2");
    rect.setAttributeNS(null, "stroke", "lightgrey");
    rect.setAttributeNS(null, "class", "cubepopup");

    container.insertBefore(rect, container.firstChild);
    g.appendChild(container);
    g.onmouseleave = () => {
      if (g.contains(container)) g.removeChild(container);
    };
  }

  invert(j) {
    return this.columns.length - j - 1;
  }

  textrows(cube: Cube): string[] {
    let res: string[] = new Array();
    let rowscount = (cube.ageMaxY - cube.ageMinY) * 4 - 1;
    if (rowscount == 3) {
      res.push(cube.subUnit.slice(0, this.cubesCharcount));
      res.push(cube.subUnit.slice(this.cubesCharcount, 2 * this.cubesCharcount));
      if (String(cube.sumArea + ' кв.км').length > this.cubesCharcount)
        res.push(cube.sumArea + '');
      else
        res.push(cube.sumArea + ' кв.км');
    }
    else {
      let regexp = new RegExp('.{1,' + this.cubesCharcount + '}', 'g');
      let subunit = cube.subUnit.match(regexp) as string[];
      for (let i = 0; i < subunit.length && i < rowscount / 2; i++) {
        res.push(subunit[i])
      }
      if (String(cube.sumArea + ' кв.км').length > this.cubesCharcount) {
        res.push(cube.sumArea + '');
        res.push(' кв.км.');
      }
      else
        res.push(cube.sumArea + ' кв.км');
      let descr = cube.rus_txt.match(regexp) as string[];
      for (let i = 0; i < descr.length && i < rowscount / 2; i++) {
        res.push(descr[i])
      }
    }

    return res.splice(0, rowscount);
  }

}
