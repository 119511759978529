import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StratService, sNode } from '../_services/strat.service';
import { ViewParams } from '../_models/viewParams';

// пространства имен
const svgns = 'http://www.w3.org/2000/svg';
const xlink = 'http://www.w3.org/1999/xlink';
@Component({
  selector: 'app-strat-ages',
  templateUrl: './strat-ages.component.html',
  styleUrls: ['./columns.component.scss']
})
export class StratAgesComponent implements OnInit {
  @Output() periodSelected = new EventEmitter<sNode>();
  nodes: sNode[];
  readonly coeffY = ViewParams.coeffY;
  readonly coeffX = ViewParams.coeffX;
  readonly darkgrey = ViewParams.darkgrey;
  readonly hdHeight = ViewParams.hdHeight;
  // temporary variables
  currentY: number = 0;
  currentWidth: number = this.coeffX;
  currentTxt: string[] = new Array();
  currentFnt: number = 12;
  currentWht: string = '500';
  currentLth: number = 300;
  currentTextDX: number = this.coeffX;

  constructor(private strat: StratService) {
  }

  ngOnInit() {
    console.log('StratAgesComponent is init');
    this.strat.getStratTree().then(res => {
      this.nodes = res;
    });
  }

  addselection(i) {
    let node = this.nodes[i];
    this.periodSelected.emit(node);
  }
  calculate(id: number): number {
    this.calculateFont(id);
    return 1;
  }
  calculateFont(id: number): number {
    let max = 24;
    let min = 12;
    let node = this.nodes[id];
    let text = node.title;
    this.currentFnt = min;
    this.currentTxt = [text];
    this.currentWht = '500';

    if (text.length * max < node.h * this.coeffY) { // влезает
      this.currentFnt = max;
      this.currentWht = '300';
    }
    if (text.length * min > node.h * this.coeffY) { // не влезает
      if (text.indexOf(' ') !== -1) {
        this.currentTxt = text.split(' '); // разбить строку
      }
      else { // разбить слово
        if (text.length < 12) {
          this.currentTxt = [text.substring(0, text.length - 4) + '-',
          text.substring(text.length - 4, text.length)]
        }
        else {
          this.currentTxt = [text.substring(0, text.length - 6) + '-',
          text.substring(text.length - 6, text.length)]
        }
      }
    }
    if (text == "Неоплейстоцен, верхнее звено") {
      this.currentTxt = ["Неоплей-", "стоцен,", "верхний"];
    }
    if (text == "Неоплейстоцен, среднее звено") {
      this.currentTxt = ["Неоплей-", "стоцен,", "средний"];
    }
    if (text == "Неоплейстоцен, нижнее звено") {
      this.currentTxt = ["Неоплей-", "стоцен,", "нижний"];
    }
    if (text == "Эоплейстоцен верхний") {
      this.currentTxt = ["Эоплей-", "стоцен,", "верхний"];
    }
    if (text == "Бартонский ярус (палеогеновая система)") {
      this.currentTxt = ["Бартон-", "ский,"];
    }
    //this.currentTxt.reverse();
    if (node.isLeaf) {
      this.currentWidth = (5 - node.level) * this.coeffX;
    }
    else {
      this.currentWidth = this.coeffX;
    }
    this.currentTextDX = Math.floor(this.currentWidth / (this.currentTxt.length + 1));
    return;
  }
  calculateLen(text: string, height: number, fontsize: number): number { // text length
    if (text == undefined) return 0;
    let coeff = Math.floor(height / text.length / fontsize * 100);
    if (coeff > 100) { // много места
      return Math.floor(text.length * fontsize * 0.8) - 10;
    }
    if (coeff > 70) { // норм места
      return Math.floor(text.length * fontsize * 0.7) - 10;
    }
    if (coeff > 45) { // мало места
      return Math.floor(text.length * fontsize * 0.6) - 10;
    }
    return 0;
  }
  transformText(x: number, y: number, dx: number) {
    let xx = x + dx;
    //console.log("rotate(270,"+ xx +","+y+")");
    return "rotate(270," + xx + "," + y + ")";
  }
}
