import { NgModule } from '@angular/core';
import {MatButtonModule, MatCheckboxModule, 
    MatTreeModule, MatRadioModule, MatInputModule,
    MatListModule, MatProgressBarModule, MatDialogModule, MatTabsModule} from '@angular/material';
import {MatBadgeModule} from '@angular/material/badge';
import {MatIconModule} from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
@NgModule({
imports: [MatSnackBarModule, MatSelectModule, MatProgressBarModule, MatButtonModule, MatCheckboxModule, 
    MatTreeModule, MatIconModule, MatBadgeModule,MatRadioModule,MatInputModule,MatListModule,
    MatDialogModule, MatTabsModule],
exports: [MatSnackBarModule, MatSelectModule, MatProgressBarModule, MatButtonModule, MatCheckboxModule, 
    MatTreeModule, MatIconModule, MatBadgeModule,MatRadioModule,MatInputModule,MatListModule,
    MatDialogModule, MatTabsModule],
declarations: []
})
export class MaterialAppModule { }